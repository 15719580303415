//CSS
import "/node_modules/bootstrap/dist/css/bootstrap.css"
import "/node_modules/@fortawesome/fontawesome-free/css/all.css"
import "/node_modules/magnific-popup/dist/magnific-popup.css"
import "./../css/styles.css"

// JS
import "/node_modules/bootstrap/dist/js/bootstrap.bundle.js"
import "/node_modules/jquery-easing/jquery.easing.1.3"
import "/node_modules/morphext/dist/morphext";
import "/node_modules/bootstrap/dist/js/bootstrap.bundle"
import "/node_modules/magnific-popup/dist/jquery.magnific-popup.js"
import "/node_modules/jquery-lazy/jquery.lazy"
import Cookies from 'js-cookie'

import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import 'dayjs/locale/pt-br'

/* Preloader */
$(window).on('load', function() {
    var preloaderFadeOutTime = 500;

    function hidePreloader() {
        var preloader = $('.spinner-wrapper');
        setTimeout(function() {
            preloader.fadeOut(preloaderFadeOutTime);
        }, 500);
    }
    hidePreloader();
});

/* Navbar Scripts */
// jQuery to collapse the navbar on scroll
$(window).on('scroll load', function() {
    if ($(".navbar").offset().top > 20) {
        $(".fixed-top").addClass("top-nav-collapse");
    } else {
        $(".fixed-top").removeClass("top-nav-collapse");
    }
});

// jQuery for page scrolling feature - requires jQuery Easing plugin
$(function() {
    $(document).on('click', 'a.page-scroll', function(event) {
        var $anchor = $(this);
        $('html, body').stop().animate({
            scrollTop: $($anchor.attr('href')).offset().top
        }, 600, 'easeInOutExpo');
        event.preventDefault();
    });
});

/* Back To Top Button */
// create the back to top button
$('body').prepend('<a href="body" class="back-to-top page-scroll">Back to Top</a>');
var amountScrolled = 700;
$(window).scroll(function() {
    if ($(window).scrollTop() > amountScrolled) {
        $('a.back-to-top').fadeIn('500');
    } else {
        $('a.back-to-top').fadeOut('500');
    }
});

// closes the responsive menu on menu item click
$(".navbar-nav li a").on("click", function(event) {
    if (!$(this).parent().hasClass('dropdown'))
        $(".navbar-collapse").collapse('hide');
});

/* Rotating Text - Morphtext */
$("#js-rotating").Morphext({
    // The [in] animation type. Refer to Animate.css for a list of available animations.
    animation: "fadeIn",
    // An array of phrases to rotate are created based on this separator. Change it if you wish to separate the phrases differently (e.g. So Simple | Very Doge | Much Wow | Such Cool).
    separator: ",",
    // The delay between the changing of each phrase in milliseconds.
    speed: 2000,
    complete: function() {
        // Called after the entrance animation is executed.
    }
});

//Submit player on button click
$("body").on("click", '.search_user_btn', function(e) {
    var player = $(this).parent().siblings('input').val();
    window.location.href = "/player/" + player;
});

//Submit player on enter
$("body").on("keypress", '.search_user', function(event) {
    var key = (event.key ? event.key : event.which);
    if (key == 'Enter' || key == '13') {
        var player = $(this).val();
        window.location.href = "/player/" + player;
    }
});

//Human readable date
dayjs.extend(relativeTime)
dayjs.locale('pt-br')

$(".time strong").each(function(i, e) {
    var time = dayjs().to(dayjs.unix($(this).text()));
    $(e).text(time);
})

//Lightbox
$('.lightbox').magnificPopup({
    type: 'image',
});

//Lazy loader
$('.lazy').lazy();

//LGPD
if (Cookies.get('lgpd') != "true") {
    $('#lgpd-bar').removeClass('invisible');
    $('body').on('click', "#lgpd-btn", function() {
        Cookies.set('lgpd', 'true', { expires: 365 })
        $('#lgpd-bar').addClass('invisible');
    });
}